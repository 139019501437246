<template>
  <v-app>
    <div class="page">
      <div id="newheader"><newheader /></div>
      <div id="concept">
        <v-main>
          <router-view />
        </v-main>
        <!-- <orderWindow /> -->
      </div>
      <div id="sidebar"><sidebar /></div>
    </div>
  </v-app>
</template>

<script>
export default {
  name: "dashboard",

  components: {
    sidebar: () => import(/* webpackChunkName: "sidebar" */ "../views/sidebar"),
    newheader: () =>
      import(/* webpackChunkName: "newheader" */ "../views/header"),
  }
};
</script> 

<style>
.page {
  display: grid;
  grid-template-columns: 72px 1fr 400px;
  grid-template-rows: 56px 1fr 400px;
  grid-template-areas:
    "sidebar header header"
    "sidebar main main" !important;
  /* overflow: hidden !important; */
  height: 100vh !important;
}

#newheader {
   grid-area: header;
    height: 56px;
    left: 72px;
    width: 100% !important;
    width: calc(100vw - 72px);
    position: fixed;
    border-bottom: solid 1px #a2a2a2;
    z-index: 5;
    background-color: white;
}

#concept {
  grid-area: main;
  /* overflow-x: hidden !important; */
  height: calc(101vh - 56px);
  background: #f9f9fb;
}

#sidebar {
  grid-area: sidebar;
  position: fixed;
}

#content1 {
  grid-area: content1;
  border-left: solid 1px #a2a2a2;
}
</style>